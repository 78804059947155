<template>
  <app-list-view
    server-side
    app="team"
    model="teamheader"
    api-url="team/team-header/"
    :title="$t('menu.team')"
    :createTo="{ name: 'teamCreate' }"
    :editTo="{ name: 'teamEdit' }"
    :headers="headers"
  >
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'teamList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('fields.teamName'),
          value: 'name'
        },
        {
          text: this.$t('fields.teamMember'),
          value: 'teamDetail_id',
          chips: {
            color: 'secondary',
            icon: 'mdi-account-circle-outline',
            value: 'contact_id',
            subValue: 'name'
          },
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'team'
    })
  }
}
</script>
